import utils from "../utils";
import env from '../env';
import { isMin } from '../lib/css-emotion'

export const isMobile = () => {
  // isMin.md() ==> min-width: 768px
  return !isMin.md()
}

export function resourcePath(url){
  if (!url) return null;
  if (url && url.indexOf('/assets/') === 0) return url;
  return utils.url.isExternalLink(url)? url : env.resourceBase + url;
}

export function fullUrl(url){
  return utils.url.isExternalLink(url)? url : env.baseOrigin + url;
}

export const isNZ = env.locale === 'nz'

export function callLiveChart() {
  const existingScript = document.getElementById('live__chart');
  if (!existingScript) {
    // if (env.country == "au") { 
    // }
    const script = document.createElement('script');
    script.type = `text/javascript`;
    script.text = `window.__lc = window.__lc || {};
                    window.__lc.license = 15482439;
                    ;(function(n,t,c){function i(n){return e._h?e._h.apply(null,n):e._q.push(n)}var e={_q:[],_h:null,_v:"2.0",on:function(){i(["on",c.call(arguments)])},once:function(){i(["once",c.call(arguments)])},off:function(){i(["off",c.call(arguments)])},get:function(){if(!e._h)throw new Error("[LiveChatWidget] You can't use getters before load.");return i(["get",c.call(arguments)])},call:function(){i(["call",c.call(arguments)])},init:function(){var n=t.createElement("script");n.async=!0,n.type="text/javascript",n.src="https://cdn.livechatinc.com/tracking.js",t.head.appendChild(n)}};!n.__lc.asyncInit&&e.init(),n.LiveChatWidget=n.LiveChatWidget||e}(window,document,[].slice))`;
    script.id = 'live__chart';
    document.head.appendChild(script);
  }
}