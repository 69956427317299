/** @jsx jsx */
import { jsx } from "@emotion/core";
import utils from "../../../utils";
import { SiteLink, useGet } from "../../../components";
import { tileStyle } from "./productTileStyle";
import React, { Fragment, useState, useEffect } from "react";
import { createPortal } from "react-dom";
import env from "../../../env";
import { AddToCart } from "../product/addToCart";
//import { IoIosStar } from "react-icons/io";
import { AiOutlineClose } from "react-icons/ai";

function Portal({ children }) {
  const [el] = useState(document.createElement("div"));
  useEffect(() => {
    let mount = document.getElementById("portal-root");
    mount.appendChild(el);
    return () => mount.removeChild(el);
  }, [el]);
  return createPortal(children, el);
}

export function ProductTile({ product, openClikcPopup = false }) {
  const s = tileStyle;
  const imageUrl = product.thumbnailUrl
    ? utils.site.resourcePath(product.thumbnailUrl)
    : "/assets/product-empty.png";
  //const imageUrl = 'https://florablume.stg5.jaba.com.au/sites/florablume/media/products/a-touch-of-white.png';
  const [openProductViewPopup, setOpenProductViewPopup] = useState(false);
  //console.log("product : ", product)

  const productUrl = product.productUrl
    ? product.productUrl
    : `/product/${product.productSlug}`;

  const schemaImages = "https://www.florablume.com.au" + product.thumbnailUrl;
  React.useEffect(() => {
    const pathname = window.location.pathname;
    const settingSchemaInterval = setInterval(() => {
      const productStructuredData = {
        "@context": "https://schema.org/",
        "@type": "Product",
        "name": product.productName,
        "url": "https://www.florablume.com.au" + pathname,
        "image": schemaImages,
        "brand": {
          "@type": "Brand",
          "name": "Flora Blume",
        },
        "offers": {
          "@type": "Offer",
          "url": "https://www.florablume.com.au" + pathname,
          "priceCurrency": "AUD",
          "price": product.priceHelpText + "",
          "availability": "https://schema.org/InStock",
          "itemCondition": "https://schema.org/NewCondition",
        },
      };
      const productSchemaScript = document.createElement("script");
      productSchemaScript.id = "productSchemaScript";
      productSchemaScript.type = "application/ld+json";
      productSchemaScript.innerHTML = JSON.stringify(productStructuredData);
      document.body.appendChild(productSchemaScript);
      clearInterval(settingSchemaInterval);
    }, 100);
    return () => {
      clearInterval(settingSchemaInterval);
    };
    // eslint-disable-next-line
  }, []);

  function openProductViewPopupF() {
    setOpenProductViewPopup(true);
    document.body.style.overflow = "hidden";
  }

  function closeProductViewPopupF() {
    setOpenProductViewPopup(false);
    document.body.style.overflow = "unset";
  }

  return (
    <Fragment>
      {openProductViewPopup && (
        <Portal>
          <ProductViewPopup
            s={s}
            closeProductViewPopupF={closeProductViewPopupF}
            product={product}
          />
        </Portal>
      )}
      <div css={[s.tile]}>
        {openClikcPopup === false ? (
          <SiteLink css={s.tileLink} to={productUrl}>
            <TileContetns product={product} s={s} imageUrl={imageUrl} />
          </SiteLink>
        ) : (
          <div
            css={s.tileLink}
            onClick={() => {
              openProductViewPopupF();
            }}
          >
            <TileContetns product={product} s={s} imageUrl={imageUrl} />
          </div>
        )}
      </div>
    </Fragment>
  );
}

function TileContetns({ product, s, imageUrl }) {
  return (
    <Fragment>
      <div css={s.prdImage}>
        <div
          css={s.bg}
          className='product__tile__bg'
          style={{ backgroundImage: utils.css.bgUrlStyle(imageUrl) }}
        ></div>
      </div>

      <div css={s.name}>{product.productName}</div>

      <div css={s.price}>
        {product.productStatus !== 0
          ? product.productStatusText
          : product.priceTextOveriding
          ? product.priceTextOveriding
          : product.priceHelpText && product.priceHelpText !== ""
          ? product.priceHelpText
          : "AU" + utils.money.formatMoney(product.priceIncTax)}
      </div>
    </Fragment>
  );
}

function ProductViewPopup({ s, closeProductViewPopupF, product }) {
  const get = useGet();

  useEffect(() => {
    const url = `/api/product/getProduct/${product.productSlug}`;
    get.send(env.apiBase + url);
    // eslint-disable-next-line
  }, []);

  const data = get.response;

  return (
    <Fragment>
      <div
        css={s.portal__background}
        className='portal__background'
        onClick={closeProductViewPopupF}
      ></div>
      <div css={s.portal__popupcontainer} className='portal__popupcontainer'>
        <div css={s.related__product__head} className='related__product__head'>
          <img src='/assets/logos/logo.png' alt='logo' />
          <div
            style={{
              position: "absolute",
              top: "50%",
              right: "0px",
              transform: "translateY(-50%)",
              color: "white",
              fontSize: "2rem",
              cursor: "pointer",
            }}
            onClick={closeProductViewPopupF}
          >
            <AiOutlineClose />
          </div>
        </div>
        {data ? (
          <div
            css={s.related__product__contents}
            className='related__product__contents row'
          >
            <div
              className='col-12 col-md-5'
              style={{ paddingLeft: "0px", paddingRight: "0px" }}
            >
              <div
                css={s.related__product__img}
                className='related__product__img'
              >
                <img src={data.thumbnailUrl} alt={`img`} />
                {/* <img src={'https://florablume.stg5.jaba.com.au' + data.thumbnailUrl} alt={`img`} /> */}
              </div>
            </div>
            <div
              className='col-12 col-md-7'
              style={{ backgroundColor: "white" }}
            >
              <h1
                className='related__product__title'
                style={{ textTransform: "uppercase" }}
                css={s.related__product__title}
              >
                <strong>{data.productName}</strong>
              </h1>
              <div
                className='related__product__desc'
                css={s.related__product__desc}
                dangerouslySetInnerHTML={{ __html: data.description }}
              ></div>
              {data.productStatus === 0 && <AddToCart product={data} />}
            </div>
          </div>
        ) : (
          <div
            css={s.related__product__contents}
            className='related__product__contents row'
          >
            <div className='col-12'> Loading ... </div>
          </div>
        )}
      </div>
    </Fragment>
  );
}
