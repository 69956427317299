import { fb } from '../../../lib/form';
export function getCategoryListModel(data, defaultCategories) {
  
  let options = [{ name: 'All', value: '' }, { name: 'Favourites', value: '_favourites_' }];
  
  // let options = [];
  // if (defaultCategories.length === 1){
  //   options.push({ name: 'Favourites', value: '_favourites_' });
  // }
  // else{
  //   options.push({ name: 'All', value: '' });
  //   options.push({ name: 'Favourites', value: '_favourites_' });
  // }
  
  if (data.length > 0) {
    data.forEach(el => {
      options.push({ name: el.categoryName, value: el.categoryId });
    });
  }

  let categoryList = "";
  if (defaultCategories.length === 1) {
    categoryList = defaultCategories[0];
  }

  const model = fb.group({
    categoryList: [categoryList || '', [], {
      label: 'FILTER BY',
      type: 'select',
      options: options
    }]
  });
  return model;
}