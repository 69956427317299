//import React from 'react';
import cmsUtils from '../../../cms/utils/cmsUtils';
import utils from '../../../utils';
import { SiteLink } from '../../../components';

/** @jsx jsx */
import { jsx } from '@emotion/core'
import style from './style';

export function PageTilesBsyc(props) {
  const item = props.item;
  //const detailHoverBgColor = props.detailHoverBgColor ? props.detailHoverBgColor : "black";
  const cmsOption = props.cmsOption || {};
  const pages = getPages(cmsUtils.payload(item, 'PageObjects'))

  const pageTileElts = pages.map((page, index) =>
    <Tile page={page} key={index} disableLink={cmsOption.isCmsEdit} idx={index}></Tile>
  );

  //const cssClass = utils.classNames('cms_item', 'pageTiles tiles', item.cssClass || item.anchorName || '');
  const cssClass = utils.classNames('cms_item', 'pageTiles__big', item.cssClass || item.anchorName || '');

  return (
    <div className={cssClass} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName}
      css={style.pageTiles__big}>
      {pageTileElts}
    </div>
  );
}

function getPages(pagesSerialized) {
  if (pagesSerialized) {
    return JSON.parse(pagesSerialized);
  }
  return [];
}

function Tile(props) {
  const page = props.page;
  //const imageUrl = utils.site.resourcePath(page.imageUrl);
  const disableLink = props.disableLink;
  //const colorTheme = page.colorTheme;
  //const index = props.idx;

  const tileImgs = (page.bottomImageUrl && page.bottomImageUrl !== '') ? page.bottomImageUrl.split("|") : [];
  //const tileImgs = ['https://florablume.stg5.jaba.com.au/sites/florablume/media/images/ocean2_269430438.jpg','https://florablume.stg5.jaba.com.au/sites/florablume/media/images/ocean2_269430438.jpg','https://florablume.stg5.jaba.com.au/sites/florablume/media/images/ocean2_269430438.jpg'];
  //console.log("tileImgs", page);

  return (

    <div css={style.pageTile__big} className="pageTile__big">
      <SiteLink className="pageTile__big__content" css={style.pageTile__big__content} to={disableLink ? '' : page.pageUrl}>
        <div className='pageTile__big__img__container' css={style.pageTile__big__img__container}>
          
          <div className="pageTile__big__bg" css={style.pageTile__big__bg}>
            <div style={{ backgroundImage: utils.css.bgUrlStyle(tileImgs[0]) }}></div>
          </div>

          <div className="pageTile__big__bg__2__c" css={style.pageTile__big__bg__2__c}>
            <div className="pageTile__big__bg__2 pageTile__big__bg__2__1" css={style.pageTile__big__bg__2} style={{ backgroundImage: utils.css.bgUrlStyle(tileImgs[1]) }}></div>
            <div className="pageTile__big__bg__2 pageTile__big__bg__2__2" css={style.pageTile__big__bg__2} style={{ backgroundImage: utils.css.bgUrlStyle(tileImgs[2]) }}></div>
          </div>

        </div>

        <div className='pageTile__big__title' css={style.pageTile__big__title}>
            {page.pageTitle}
        </div>

      </SiteLink>
    </div>
  )
}