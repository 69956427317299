//import { css } from '@emotion/core'
import { mq } from '../../../cssInJs'

export default {
    
    pageTiles__small : {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        margin: '2rem -10px',
        overflow: 'hidden'
    },
    
    pageTile__small : mq({
        flex: ['1 0 100%',null, '1 0 33.3%', '1 0 25%', null, '1 0 20%'],
        maxWidth: ['100%',null, '33.3%', '25%', null, '20%'],
        padding: '10px'
    }),

    pageTile__small__content : {
        textDecoration: 'none',
        '&:hover' : {
            textDecoration: 'none',
            '.pageTile__small__img, .pageTile__small__bg' : {
                filter : 'unset'
            }
        }
    },

    pageTile__small__container : {
        position: 'relative'
    },

    pageTile__small__img : {
        width: '100%',
        filter: `url("data:image/svg+xml;utf8,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' height='0'><filter id='greyscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0' /></filter></svg>#greyscale")`,
    },

    pageTile__small__bg : {
        filter: `url("data:image/svg+xml;utf8,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' height='0'><filter id='greyscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0' /></filter></svg>#greyscale")`,
        paddingTop : '100%',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat'
    },

    pageTile__small__title : mq({
        textAlign: 'center',
        fontWeight: '500',
        fontSize: ['1rem',null,'1rem'],
        paddingTop : '0.7rem',
        paddingBottom: ['2.1rem','2.5rem','1.5rem']
    }),

    pageTile__small__border : {
        position : 'absolute',
        border: '2px solid black',
        width : '90%',
        height: '90%',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    }

}