import cmsUtils from '../../../cms/utils/cmsUtils';
import utils from '../../../utils';
import { SiteLink } from '../../../components';

/** @jsx jsx */
import { jsx } from '@emotion/core'
import style from './style';

export function PageTilesSmall(props) {
    const item = props.item;
    //const detailHoverBgColor = props.detailHoverBgColor ? props.detailHoverBgColor : "black";
    const cmsOption = props.cmsOption || {};
    const pages = getPages(cmsUtils.payload(item, 'PageObjects'))

    const pageTileElts = pages.map((page, index) =>
        <Tile page={page} key={index} disableLink={cmsOption.isCmsEdit} idx={index}></Tile>
    );

    //const cssClass = utils.classNames('cms_item', 'pageTiles tiles', item.cssClass || item.anchorName || '');
    const cssClass = utils.classNames('cms_item', 'pageTiles__small', item.cssClass || item.anchorName || '');

    return (
        <div className={cssClass} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName}
            css={style.pageTiles__small}>
            {pageTileElts}
        </div>
    );
}

function getPages(pagesSerialized) {
    if (pagesSerialized) {
        return JSON.parse(pagesSerialized);
    }
    return [];
}

function Tile(props) {
    const page = props.page;
    const imageUrl = utils.site.resourcePath(page.imageUrl);
    //const imageUrl = "https://florablume.stg5.jaba.com.au/sites/florablume/media/images/flora-blume-flowers-wall-03.jpg";

    const disableLink = props.disableLink;
    //const colorTheme = page.colorTheme;
    //const index = props.idx;

    //const tileImgs = (page.bottomImageUrl && page.bottomImageUrl !== '') ? page.bottomImageUrl.split("|") : [];
    //const tileImgs = ['https://florablume.stg5.jaba.com.au/sites/florablume/media/images/ocean2_269430438.jpg','https://florablume.stg5.jaba.com.au/sites/florablume/media/images/ocean2_269430438.jpg','https://florablume.stg5.jaba.com.au/sites/florablume/media/images/ocean2_269430438.jpg'];
    //console.log("tileImgs", page);

    return (

        <div css={style.pageTile__small} className="pageTile__small">
            <SiteLink className="pageTile__small__content" css={style.pageTile__small__content} to={disableLink ? '' : page.pageUrl}>
                <div className='pageTile__small__container' css={style.pageTile__small__container}>
                    {/* <img className='pageTile__small__img' css={style.pageTile__small__img} src={imageUrl} alt='tile__image' /> */}
                    <div className='pageTile__small__bg' css={style.pageTile__small__bg} style={{ backgroundImage: utils.css.bgUrlStyle(imageUrl) }}></div>
                    <div className='pageTile__small__title' css={style.pageTile__small__title}>
                        {page.pageTitle}
                    </div>
                    <div className='pageTile__small__border' css={style.pageTile__small__border}></div>
                </div>
            </SiteLink>
        </div>
    )
}