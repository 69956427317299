/** @jsx jsx */
import { jsx } from '@emotion/core'
import style from './payStyle';
import React from 'react';

import { SiteLink, usePost } from '../../../components';
import { Button, ErrorMessage } from '../../../components';
import env from '../../../env';
import { useRouter } from '../../../components';
import { initStripe } from './payStripe';
import { CartSummary } from '../cartSummary/summary';
import { gtm } from '../../../lib/tracking';

export function Pay({ cart, model, form, payStripe }) {

  const [stripe, setStripe] = React.useState(null);
  const [card, setCard] = React.useState(null);
  const [error, setError] = React.useState(null);

  const post = usePost();
  const { Redirect } = useRouter()

  React.useEffect(() => {
    const { stripe, card } = initStripe(setError)
    setStripe(stripe);
    setCard(card);
  }, [])

  function payNow() {
    if (error) return;
    stripe.createToken(card).then(function (result) {
      if (result.error) {
        setError(result.error.message);
      } else {
        setError(null);
        // Send the token to your server.
        onSubmit(result.token.id);
      }
    });
  }

  function onSubmit(tokenId) {
    console.log(tokenId)
    post.send(env.apiBase + "/api/cart/payStripe", { tokenId: tokenId, itemTotalAmount: cart.itemTotalAmount, cartId: env.getDevCartId() });
  }

  if (post.done()) {
    env.setDevCartId(null);
    
    const _products = [];
    for (let i in cart.orderItems) {
      if (cart.orderItems[i].orderItemType === 0) {
        let _variant = "";
        let _price = cart.orderItems[i].unitPrice;
        try {
          if (cart.orderItems[i].variantOptionsSerialised && cart.orderItems[i].variantOptionsSerialised !== '') {
            const cartItemVarinat = JSON.parse(cart.orderItems[i].variantOptionsSerialised);
            for (let n in cartItemVarinat) {
              if (n > 0) {
                _variant = _variant + ", "
              }
              _variant = _variant + cartItemVarinat[n].Name + " : " + cartItemVarinat[n].Value
            }
          }

          if(cart.orderItems[i].extSerialized && cart.orderItems[i].extSerialized !== ''){
            const extSerialized = JSON.parse(cart.orderItems[i].extSerialized);
            
            if(extSerialized !== null && extSerialized.PriceIncTax){
              _price = extSerialized.PriceIncTax;
            }
          }

        }
        catch (e) { }
        _products.push({
          'name': cart.orderItems[i].itemName,
          'price': _price,
          'brand': 'florablume',
          'category': '',
          'variant': _variant
        });
      }
    }

    const _ecommerce = {
      'ecommerce': {
        'checkout': {
          'actionField': { 'step': 1, 'option': 'Visa' },
          'products': _products
        }
      }
    };
    
    gtm.sendEvent('checkout', _ecommerce);
    return <Redirect to={`/order-thankyou?o=${post.response.results.OrderIdToken}`} />
    
  }

  return <div css={style.pay}>
    <div className="row" >
      <div className="col-md-9">
        <h3>Pay by Credit Card</h3>

        <div css={style.stripeWrapper}>
          <div className="stripe-wrapper">
            <div id="card-element"></div>
          </div>
          <div css={style.paymentInfoText}>
            Payment through encrypted gateway can be made by Visa, Mastercard or AMEX.  Please enter your credit card details to complete your order.
            For alternative payment methods please <SiteLink to="#contactForm" className="contact-link"> contact us </SiteLink>
          </div>
        </div>

      </div>

      <div className="col-md-3">
        <CartSummary cart={cart} />
      </div>
    </div>
    <div css={style.formActions}>
      <Button onClick={payNow} status={post.status}>Pay Now</Button>
      <ErrorMessage errors={error} />
      <ErrorMessage errors={post.errors} />
    </div>
  </div>
}
