//import { css } from '@emotion/core';
import { mq } from '../../cssInJs';

export default{
    
    container : mq({
        cursor: 'pointer',
        zIndex: '1000',
        position: 'fixed',
        padding: '0',
        right: ['100px',null,'130px','152px','152px'],
        display: ['none','block']
        //border: '1px solid red'
    }),
    flexContainer : {
        display: 'flex',
        transition: 'all 0.5s ease-in-out',
        '&.open' : mq({    
            //backgroundColor: 'rgba(255,255,255,0.7)',
            //backgroundColor: ['unset',null,null,null,'rgba(255,255,255,0.7)']
            backgroundColor: ['unset',null,null,null,'#ebebeb']
        }),
    },
    buttonContainer : mq({

        width : ['40px',null,'50px'],
        height : ['40px',null,'50px'],

        color: 'white',
        display: 'flex',
        alignItems: 'center',
        zIndex: 2,
        'svg' : {
            width:'100%',
            height:'100%',
            color: 'black',
            '&:hover' : {
                '.st0' : {
                    stroke:'#303030'
                }
            }
        },
        '.st0' : {
            transition: 'all 0.5s ease-in-out',
        }
    }),

    form  : mq({
        //backgroundColor: 'rgba(255,255,255,0.7)',
        paddingLeft: '10px',
        paddingRight: '10px',
        transition: 'all 0.5s ease-in-out',
        maxWidth: '0px',
        opacity: 0,
        display: 'none',
        '&.open' : {
            maxWidth: '700px',
            opacity: 1,
            display: ['none',null,null,null,'block'],
            width:['0px',null,null,null,'300px', '380px'],
        }
    }),

    formForMobile :mq({
        position: 'absolute',
        top:'60px',
        right:'0px',
        display: 'none',
        backgroundColor: 'white',
        height:'50px',
        '&.open' : {
            maxWidth: '700px',
            opacity: 1,
            display: ['block',null,null,null,'none'],
            width:['280px','300px',null,'380px','0px'],
        }
    }),

    input : {
        height: '100%',
        width:'100%',
        border: 'none',
        outline: 'none',
        backgroundColor: '#ebebeb',
        '&::placeholder' : {
            color:'black',
            fontWeight: 600
        }
    },
    scrolled : {
        // 'svg' : {
        //     color: 'white !important'
        // },
        '.st0' : {
            stroke:'#FFFFFF'
        }
    }
}


//right: ['72px',null, null, '150px', '150px'],