import React from 'react';
import { useRouter } from '../../components';
import { SiteLink } from '../../components';
//import BottomDrawer from '../../components/bottomDrawer'

export function BottomDock(props) {
  const links = [
    { url: '/flowers', title: 'FLOWERS', icon: 'flowers-icon.png', iconHover: 'flowers-icon.png' },
    { url: '/gifts', title: 'GIFTS', icon: 'gift-icon.png', iconHover: 'gift-icon.png' },
    { url: '/plants', title: 'PLANTS', icon: 'plants.png', iconHover: 'plants.png' },
    { url: '/weddings', title: 'WEDDINGS', icon: 'weddings-icon.png', iconHover: 'weddings-icon.png' },
    { url: '#contact', title: 'CONTACT', icon: 'contact.png', iconHover: 'contact.png' }
  ];

  //const path = usePath();
  const { pathname } = useRouter();

  const isCurrent = (link) => {
    return pathname && pathname.toLowerCase() === link.toLowerCase();
  }

  const linkElts = links.map((link, index) =>
    <li key={index} className={`bottomMenu__li ${isCurrent(link.url) ? 'selected' : ''}`}>
      <SiteLink to={link.url} className={`bottomMenu__link bottomMenu__link--${index}`}>
        <div className="icon">
          <img className="normal" src={`/assets/navicons/${link.icon}`} alt={link.title} />
          <img className="selected" src={`/assets/navicons/${link.iconHover}`} alt={link.title} />
        </div>
        <div>{link.title}</div>
      </SiteLink>
    </li>
  );

  // const bottomMenu = useRef(null);
  // useLayoutEffect(() => {
  //   //console.log('useLayoutEffect');
  //   const slider = new BottomDrawer(bottomMenu.current);
  //   setTimeout(() => slider.init(), 1000)
  // }, [])

  return (
    <>
      <nav className="bottomMenu">
        <ul className="bottomMenu__links">
          {linkElts}
        </ul>
      </nav>
    </>
  );
}
