import { css  } from '@emotion/core'
import { mq, variables } from '../../cssInJs'

export default {
  header__black__bar : mq({
    backgroundColor : 'black',
    color : 'white',
    height: variables.headerBlackBar + 'px',
    display : 'flex',
    justifyContent: 'center',
    alignItems : 'center',
    position : 'fixed',
    width : '100%',
    top: '0px',
    zIndex: '2',
    fontSize : ['0.8rem', null, '1rem']
  }),
  
  top: css(mq({
    //height: [44, null, 80],
    height: [44, null, 56],
    background: 'white',
    position: 'fixed',
    width: '100%',
    zIndex: '999',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'all 0.3s',
    top : variables.headerBlackBar + 'px'
  })),
  topScrolled: css(mq({
    //height: [44, null, 56],
    backgroundColor: 'black',
    '.menu__text' : {
      color: 'white'
    }
  })),
  logo: css(mq({
  
  
  })),
  logoImg: css(mq({
    display: 'block',
    height: [22, null, 45],
  })),
  logoScrolled: css(mq({

  })),

  menu__text : mq({
    position: 'fixed',
    right: ['40px',null,'80px'],
    display : ['none', null, null,'block'],
    top : [ (variables.headerBlackBar + 14) + 'px', null, (variables.headerBlackBar + 16) + 'px'],
    fontSize: ['0.8rem',null,'1rem']
  })
}