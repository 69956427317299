import { mq } from '../../cssInJs';

export default {
    container: mq({
        cursor: 'pointer',
        zIndex: '1000',
        position: 'fixed',
        padding: '0',
        //top: 4,
        right: ['50px', null, '80px', '95px', '95px'],
        //display : ['none', null, null,'block'],
        //border: '1px solid red'
    }),
    countContainer: mq({
        width: ['40px', null, '50px'],
        height: ['40px', null, '50px'],
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        'svg': mq({
            width: ['100%'],
            height: ['100%'],
            '&:hover' : {
                '.st0' : {
                    stroke:'#303030'
                }
            }
            //color: 'black',
        }),
        '.st0' : {
            transition: 'all 0.5s ease-in-out',
        }
    }),
    count: mq({
        position: 'absolute',
        backgroundColor: 'red',
        color: 'white',
        borderRadius: '20px',
        padding: ['0.35rem 0.4rem 0.2rem', null, '0.2rem 0.4rem 0.2rem'],
        lineHeight: '1',
        top: ['3px', null, '0px'],
        left: ['1.5rem', null, '30px']
    }),
    scrolled: {
        // 'svg' : {
        //     //color: 'white'
        // }
        '.st0': {
            stroke: '#FFFFFF'
        }
    }
}