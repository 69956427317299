//import { css, keyframes  } from '@emotion/core'
//import { variables, mq } from 'cssInJs'

import { css } from '@emotion/core'
import { mq } from '../../../cssInJs'

export default {
    pageTiles__big: css({
        display: 'flex',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        margin: '2rem -10px',
        overflow: 'hidden'
    }),
    pageTile__big: css(mq({
        flex: ['1 0 100%',null,'1 0 33.3%'],
        maxWidth: ['100%',null,'33.3%'],
        padding: '1.5rem 1.5rem'
    })),

    pageTile__big__img__container: {
        display: 'flex',
        flexDirection: 'row',
        //boxShadow: '8px 8px 5px rgba(0, 0, 0, 0.04)',
        //borderRadius: '20px',
        transition: 'all 0.3s ease-in-out'
    },

    pageTile__big__bg: {
        flex: '1 0 50%',
        maxWidth: '50%',
        paddingRight: '0.3rem',
        '& > div': {
            paddingTop: '217%',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            //borderRadius: '20px',
            boxShadow: '8px 8px 5px rgba(0, 0, 0, 0.04)'
        }
    },

    pageTile__big__bg__2__c: {
        flex: '1 0 50%',
        maxWidth: '50%',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        paddingLeft: '0.3rem'
        //marginLeft: '0.2rem'
    },

    pageTile__big__bg__2: {
        //paddingTop: '56.25%',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        flex: '1 0 100%',
        maxWidth: '100%',
        //borderRadius: '20px',
        boxShadow: '8px 8px 5px rgba(0, 0, 0, 0.04)',

        '&.pageTile__big__bg__2__1': {
            marginBottom: '0.3rem'
        },
        '&.pageTile__big__bg__2__2': {
            marginTop: '0.3rem'
        }
    },

    pageTile__big__title : {
        textAlign: 'center',
        fontSize: '1rem',
        marginTop : '1rem',
        color: 'black',
        textDecoration: 'none'
    },

    pageTile__big__content : {
        textDecoration: 'none',
        '&:hover' : {
            '.pageTile__big__img__container' : {
                transform: 'scale(1.02)',
            }
        }
    }

}